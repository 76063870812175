<template>
  <div class="admin-makeup">
    <h5>补票记录</h5>

    <div class="mt-3">
      <b-button variant="primary">导入补票/挂票记录</b-button>

      <b-table class="mt-3" hover striped head-variant="light" :items="list" :fields="fields">
      <template #cell(images)="data">
        <img :src="item" alt="" v-for="item in data['item']['images']" :key="item.index" class="mr-2" style="width: 30px;">
      </template>
      </b-table>
    </div>
  </div>
</template>
<script>
  import Lottery from "@/model/Lottery";

  export default {
    data() {
      return {
        list: [],
        fields: [
          {key:'tradeNo', label:'物流码'},
          {key:'reason', label:'异常原因'},
          {key:'handle', label:'处理办法'},
          {key:'award', label:'是否中奖'},
          {key:'amount', label:'中奖金额'},
          {key:'images', label:'图片'},
          {key:'handleDate', label:'处理日期'},
          {key:'created_at', label:'录入日期'},
        ],
      }
    },
    created() {
      Lottery.makeupList().then(response => {
        if(response.data.code === 0) {
          console.log(response.data.data);
          this.list = response.data.data;
        }
      })
    },
    methods:{
      zoom() {

      }
    }
  }

</script>