import api from "@/request/api";

export default {
    all() {
        return api.get('lottery/list');
    },
    find(id) {
        return api.get('lottery/info', {id:id});
    },
    history(id) {
        return api.get('lottery/history', {id: id});
    },

    makeupList() {
        return api.get('lottery/makeup/list');
    },

}